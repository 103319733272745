import React, { useEffect, useRef } from "react";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { useHistory, useParams } from "react-router-dom";
import SVG from "react-inlinesvg";
import {
  sortCaret,
  headerSortingClasses,
  PleaseWaitMessage,
  NoRecordsFoundMessage,
  toAbsoluteUrl,
} from "../../../../../../_metronic/_helpers";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import {
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  ConvertToDateCurrentTimeZoneOnlyDate,
  Currency,
  CustomCheckbox,
  ExportAs,
  getFromatdate,
} from "../../../../../../_metronic/_helpers/GlobalHelpers";
import "react-google-flight-datepicker/dist/main.css";
import { RangeDatePicker } from "react-google-flight-datepicker";
import moment from "moment";
import {
  ModelStateMessage,
  apiCalll,
} from "../../../../../../_metronic/_helpers/apiHelpers";
import Swal from "sweetalert2";
import Invoice from "./Invoice";
import { useReactToPrint } from "react-to-print";
import ReactSelect from "react-select";
import InvoiceShort from "./InvoiceShort";
import html2pdf from 'html2pdf.js';


const PharmacyInvoicesHistoy = (props) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = JSON.parse(localStorage.getItem("user")).token;
  const [pagination, setPagination] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);
  const [orders, setOrders] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [invoicesShort, setInvoicesShort] = useState([]);
  const [showShort, setShowShort] = useState(false);
  const [show, setShow] = useState(false);
  const params = useParams();
  const id = params.id;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [chargeSuccess, setChargeSuccess] = useState(null);
  const [isLaoding, setIsLaoding] = useState(false);
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);
  const [ids, setIds] = useState([]);
  const history = useHistory();
  const componentRef = useRef();
  const [initFilter, setinitFilter] = useState({
    page: 1,
    sizePerPage: localStorage.getItem("invoicesPageSize") ? parseInt(localStorage.getItem("invoicesPageSize")) : 10,
    sortField: "Id",
    sortOrder: "desc",
    custom: true,
    totalSize: 100,
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
    ],
  });

  const columns = [
    {
      dataField: "invoiceNumber",
      text: "Invoice #",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => <b>#{row?.invoiceNumber}</b>,
      headerSortingClasses,
    },
    {
      dataField: "created",
      text: "Created Date",
      headerFormatter: (cellContent, row) => (
        <>
          Created Date
          <OverlayTrigger
            key={"right"}
            placement={"right"}
            overlay={
              <Tooltip id={`tooltip-${"placement"}`}>{getFromatdate()}</Tooltip>
            }
          >
            <span
              style={{ cursor: "pointer" }}
              className="svg-icon menu-icon svg-icon-info"
            >
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
              />
            </span>
          </OverlayTrigger>
        </>
      ),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        // format date to dd/mm/yyyy
        <p>
          {!!row.created
            ? ConvertToDateCurrentTimeZoneOnlyDate(row.created)
            : "-"}
        </p>
      ),
      headerSortingClasses,
    },
    {
      dataField: "paidDate",
      text: "Paid Date",
      headerFormatter: (cellContent, row) => (
        <>
          Paid Date
          <OverlayTrigger
            key={"right"}
            placement={"right"}
            overlay={
              <Tooltip id={`tooltip-${"placement"}`}>{getFromatdate()}</Tooltip>
            }
          >
            <span
              style={{ cursor: "pointer" }}
              className="svg-icon menu-icon svg-icon-info"
            >
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
              />
            </span>
          </OverlayTrigger>
        </>
      ),
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        // format date to dd/mm/yyyy
        <p>
          {!!row?.paidDate
            ? ConvertToDateCurrentTimeZoneOnlyDate(row?.paidDate)
            : "-"}
        </p>
      ),
      headerSortingClasses,
    },
    {
      dataField: "totalWithTax",
      text: "Paid Amount",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>{row.totalWithTax ? Currency(row.totalWithTax): "$0.00"}</>
      ),
      headerSortingClasses,
    },
    {
      dataField: "startDate",
      text: "Pay Period ",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
        {row.startDate && moment(row.startDate).format(getFromatdate())}
        {" - "}
        {row.endDate && moment(row.endDate).format(getFromatdate())}
        </>
      ),
      headerSortingClasses,
    },
    {
      dataField: "paymentSuccess",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <span
          onClick={() => {
            if (!row.paymentSuccess) {
              Swal.fire({
                title: "Are you sure?",
                text: "You want to mark this invoice as paid!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, mark it!",
                cancelButtonText: "No, cancel!",
                reverseButtons: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  PayInvoice(row?.id);
                }
              });
            }
          }}
          className={`label label-lg text-white label-inline font-weight-bold py-4 cursor-pointer bg-${
            row?.paymentSuccess ? "success" : "danger"
          }`}
          style={{
            whiteSpace: "nowrap",
          }}
        >
          {row.paymentSuccess ? "Paid" : "Unpaid"}
        </span>
      ),
      headerSortingClasses,
    },
    {
      dataField: "pdf",
      text: "Invoice",
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <button
          onClick={() => {
              setInvoiceId(row?.id);
              handleGetTotalInvoiceByDate(row?.id);
          }}
          className={`btn btn-secondary font-weight-bolder font-size-sm`}
        >
          Invoice
        </button>
      ),
    },
    {
      dataField: "summary",
      text: "Summary",
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <button
          onClick={() => {
            handleGetTotalInvoiceShort(row?.id);
            setInvoiceId(row?.id);
          }}
          className={`btn btn-secondary font-weight-bolder font-size-sm`}
        >
          Summary
        </button>
      ),
    },
    {
      dataField: "quickbookSuccess",
      text: "QuickBooks",
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <>
          {row.quickbookSuccess === false &&  
          <OverlayTrigger
            key={"right"}
            placement={"right"}
            overlay={
              <Tooltip id={`tooltip-${"placement"}`}>
                {row?.quickbooksError && row?.quickbooksError + " - "} Click to retry
                </Tooltip> }
          >
            <span 
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "You want to retry sending this invoice to QuickBooks!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#1BC5BD",
                  cancelButtonColor: "#0058B1",
                  confirmButtonText: "Yes, I do!",
                }).then((result) => {
                  if (result.isConfirmed) {
                   handleRetryQuickBooks(row?.id); 
                  }
                });
              }}
            className="svg-icon menu-icon svg-icon-info svg-icon-2x cursor-pointer">
              <SVG
                src={toAbsoluteUrl("/media/icons/quickbooks.svg")}
              />
            </span>
          </OverlayTrigger>}

          {row?.quickbookSuccess === true && <span className="svg-icon menu-icon svg-icon-info svg-icon-2x cursor-pointer">
              <SVG
                src={toAbsoluteUrl("/media/icons/quickbooksgreen.svg")}
              />
          </span>}

          {row?.quickbookSuccess === null && <span className="svg-icon menu-icon svg-icon-info svg-icon-2x cursor-pointer">
              <SVG
                src={toAbsoluteUrl("/media/icons/quickbooksgrey.svg")}
              />
          </span>}
        </>
      ),
    },
    {
      dataField: "id",
      text: "Export",
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <button
          onClick={() => {
            Export("excel", row?.id);
          }}
          className="btn btn-success font-weight-bolder font-size-sm"
        >
          Excel
        </button>
      ),
    },
    {
      dataField: "stripeView",
      text: "Stripe View",
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <>
          {row?.stripeView && (
            <button
              onClick={() => {
                window.open(`${row?.stripeView}`, "_blank");
              }}
              className="btn btn-secondary font-weight-bolder font-size-sm"
            >
              View
            </button>
          )}
        </>
      ),
    },
  ];

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleGetTotalInvoiceByDate = (id) => {
    setIsLoading(true);
    fetch(`${BASE_URL}/Pharmacies/GetTotalInvoiceById?InvoiceId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      Allow: "GET",
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setShow(true);
          setInvoices(data[1]);
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleGetTotalInvoiceShort = (id) => {
    setIsLoading(true);
    fetch(`${BASE_URL}/Pharmacies/GetTotalInvoiceShortById?InvoiceId=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      Allow: "GET",
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setShowShort(true);
          setInvoicesShort(data[1]);
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const GetHandlerTableChange = (type, newState) => {
    let values = { ...initFilter };
    if (type === "sort") {
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
    }
    if (type === "pagination") {
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
    }
    setinitFilter(values);
  };

  function getTimeZoneOffset() {
    const offsetInMinutes = new Date().getTimezoneOffset();

    const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
    const sign = offsetInMinutes < 0 ? "+" : "-";

    return `${sign}${hours.toString().padStart(2, "")}`;
  }

  const firstLetterUppercase = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const handleGetOrders = (filter) => {
    filter.pageSize = localStorage.getItem("invoicesPageSize") ? parseInt(localStorage.getItem("invoicesPageSize")) : 10
    fetch(
      `${BASE_URL}/${props?.isMaster === true ? "PharmacyAdminManagement" : "Pharmacies"}/GetInvoices?pharmacyId=${id}&ChargeSuccess=${chargeSuccess ||
        ""}&pageNumber=${filter?.page}&pageSize=${
        filter?.sizePerPage
      }&startDate=${startDate || ""}&endDate=${endDate || ""}&search=${search ||
        ""}&timezone=${getTimeZoneOffset()}&sortOrder=${filter?.sortOrder}&sortField=${firstLetterUppercase(
          filter?.sortField
        )}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        Allow: "GET",
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setOrders(data[1]?.orders);
          setPagination(data[1]?.pagination);
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRetryQuickBooks = (id) => {
    setIsLaoding(true);
    fetch(`${BASE_URL}/Pharmacies/InsertInvoiceQuickbook?id=${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      Allow: "POST",
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (
          contentType && contentType.indexOf("application/json") !== -1
        ) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then(data => {
        if (data[0] === 200) {
          handleGetOrders(initFilter);
          Swal.fire({
            title: "Success!",
            text: "Invoice has been sent to QuickBooks successfully!",
            icon: "success",
            confirmButtonText: "Ok",
          });
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
        else if (data[0] === 400 && data[1].message) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: data[1].message,
          })
        }
        else if (data[0] === 400) {
          let tt = JSON.parse(data[1]);
          for (const [key, value] of Object.entries(tt.errors)) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: value,
            })
          }
        }
        else if (data[0] === 500) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong, please try again later!',
          })
        }
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => {
        setIsLaoding(false);
      })
  }
  useEffect(() => {
    if(isLaoding){
      Swal.fire({
        title: "Processing!",
        html: "Please wait while we are processing your request.",
        timer: 9000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log("I was closed by the timer");
        }
      });
    }

  }, [isLaoding]);

  function Export(Type, id) {
    apiCalll({
      method: "GET",
      link: `/Pharmacies/GetTotalChargeInvoiceId?InvoiceId=${id}&timezone=${getTimeZoneOffset()}`,
    })
      .then(async (result) => {
        if (result[0] === 200) {
          var listOrders = [];
          var data = result[1]?.ordersViewModel;
          data.forEach((element) => {
            delete element.actualDeliveryDate;
            delete element.fromRxDrop;
          });
          data.map((k) => {
            var a = JSON.stringify(k);
            a = a.replaceAll('"invoiceNumber":', '"Invoice Number":');
            a = a.replaceAll('"code":', '"Code":');
            a = a.replaceAll('"patientName":', '"Patient Name":');
            a = a.replaceAll('"pharmacyName":', '"Pharmacy Name":');
            a = a.replaceAll('"deliveryAppointmentDate":', '"Delivery Appointment Date":');
            a = a.replaceAll('"deliveryAppointmentToDate":', '"Delivery Appointment To Date":');
            a = a.replaceAll('"pickupAppointmentDate":', '"Pickup Appointment Date":');
            a = a.replaceAll('"pickupAppointmentToDate":', '"Pickup Appointment To Date":');
            a = a.replaceAll('"actualPickupDate":', '"Actual Pickup Date":');
            a = a.replaceAll('"pickupLocation":', '"Pickup Location":');
            a = a.replaceAll('"deliveryLocation":', '"Delivery Location":');
            a = a.replaceAll('"distance":', '"Distance":');
            a = a.replaceAll('"driver":', '"Driver":');
            a = a.replaceAll('"codPaymentMethod":', '"COD Payment Method":');
            a = a.replaceAll('"charge":', '"Charge":');
            a = a.replaceAll('"codPaid":', '"COD Paid":');
            a = a.replaceAll('"pfcTotal":', '"Total PFC":');
            a = a.replaceAll('"total":', '"Total":');
            a = a.replaceAll('"amountDue":', '"Amount Due":');
            a = JSON.parse(a);
            listOrders.push(a)
          })
          const entity = "Invoice" + "_" + (data[0]?.invoiceNumber || "");
          ExportAs(listOrders || [], Type, entity);
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function PayInvoice(id) {
    return apiCalll({
      method: "GET",
      link: `/Pharmacies/SetInvoiceAsPaid?invoiceId=${id}`,
    })
      .then((res) => {
        if (res[0] === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Invoice paid successfully",
          });
          handleGetOrders(initFilter);
        } else if (res[0] === 402) {
          Swal.fire({
            title: "Info!",
            text: "You have to subscribe first",
            icon: "info",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/user-profile");
            }
          });
        } else {
          ModelStateMessage(res);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      handleGetOrders(initFilter);
    }, 500);
    return () => {
      clearTimeout(debounceSearch);
    };
  }, [initFilter, search, startDate, endDate, chargeSuccess]);

  const paginationOptions = {
    custom: true,
    totalSize: pagination?.totalItemCount || 0,
    sizePerPageList: initFilter?.sizePerPageList || [],
    sizePerPage: pagination?.pageSize || 10,
    page: pagination?.pageNumber || 1,
  };

  const status = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Paid",
      value: "true",
    },
    {
      label: "Unpaid",
      value: "false",
    },
  ];

  const downloadBillAsPDF = async () => {
    const element = componentRef.current;

    const timestamp = new Date().getTime();
    const invoice = show ? invoices : invoicesShort;
    const filename = invoice?.invoiceNumber
        ? `invoice-${invoice.invoiceNumber}.pdf`
        : `invoice-${timestamp}.pdf`;

    const options = {
        margin: 0,
        filename,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 1, logging: false },
        jsPDF: {
            unit: 'in',
            format: 'a3',
            orientation: 'portrait',
        },
    };

    try {
        await html2pdf().set(options).from(element).save();
    } finally {
        setDownloadIsLoading(false);
    }
};

function SendInvoiceByEmail() {
  setIsSending(true);
  apiCalll({
    method: "GET",
    link: `/Invoicing/SendEmailInvoice?id=${invoiceId}`,
  })
    .then(async (data) => {
      if (data[0] === 200) {
        Swal.fire({
          title: "Success!",
          text: "Invoice sent successfully!",
          icon: "success",
          confirmButtonText: "Ok",
        });
      }else if (data[0] === 400 && data[1].message) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data[1].message,
        });
      } else if (data[0] === 400) {
        let tt = JSON.parse(data[1]);
        for (const [key, value] of Object.entries(tt.errors)) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: value,
          });
        }
      } else if (data[0] === 401) {
        history.push("/logout");
      } else if (data[0] === 402) {
        Swal.fire({
          title: "Info!",
          text: "You have to subscribe first!",
          icon: "info",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            history.push("/user-profile");
          }
        });
      }
    })
    .catch((error) => {
      console.log("error", error);
    }).finally(() => {
      setIsSending(false);
    }
    );
}

const handleRowSelect = (row, isSelect) => {
  if (isSelect) {
    setIds((prevIds) => [...prevIds, row.id]);
  } else {
    setIds((prevIds) => prevIds.filter((id) => id !== row.id));
  }
};

const handleSelectAll = (isSelect, rows) => {
  if (isSelect) {
    setIds(rows.map((row) => row.id));
  } else {
    setIds([]);
  }
};

const selectRow = {
  mode: 'checkbox',
  clickToSelect: false,
  selected: ids,
  onSelect: handleRowSelect,
  onSelectAll: handleSelectAll,
  selectionRenderer: (props) => <CustomCheckbox {...props} />,
  selectionHeaderRenderer: (props) => <CustomCheckbox {...props} />,
};


  const generateInvoice = () => {
    setIsInvoiceLoading(true)
    fetch(
      `${BASE_URL}/PharmacyAdminManagement/GetMasterInvoice?pharmacyAdminId=${id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(ids),
        Allow: "POST",
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          props.setData(data[1]);
          props.showInvoiceModal();
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
        else {
          ModelStateMessage(data);
        }
      })
      .catch((error) => {
        console.log(error);
      }).finally(()=>{
       setIsInvoiceLoading(false)
      })
  };

  const handleGetInvoicesByFilter = () => {
    setIsInvoiceLoading(true)
    fetch(
      `${BASE_URL}/PharmacyAdminManagement/GetMasterInvoiceFilter?pharmacyAdminId=${id}&search=${search ||
        ""}&startDate=${startDate || ""}&endDate=${endDate ||
        ""}&ChargeSuccess=${chargeSuccess ||
        ""}&timezone=${getTimeZoneOffset()}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        Allow: "POST",
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          props.setData(data[1]);
          props.showInvoiceModal();
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
        else {
          ModelStateMessage(data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsInvoiceLoading(false)
      });
  };

  useEffect(() => {
    if(props?.getIds){
      props.getIds(ids);
    }
  }, [ids]);

  useEffect(() => {
    if(props?.setEndDate){
      props.setEndDate(endDate);
    }
  }, [endDate]);

  useEffect(() => {
    if(props?.setStartDate){
      props.setStartDate(startDate);
    }
  }, [startDate]);

  useEffect(() => {
    if(props?.setSearchInvoice){
      props.setSearchInvoice(search);
    }
  }, [search]);

  return (
    <>
      <button
        type="button"
        hidden
        id="refreshMasterInvoice"
        className="btn btn-success mt-4"
        onClick={() => {
          setIds([])
          handleGetOrders(initFilter);
        }}
          >
           Refresh 
      </button>
      <div className="row mb-7">
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            name="search"
            placeholder="Search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <small className="form-text text-muted">
            <b>Search</b> in all fields
          </small>
        </div>
        <div className="col-md-4 ">
          <RangeDatePicker
            startDate={
              startDate !== null && moment(startDate).format("YYYY-MM-DD")
            }
            endDate={endDate !== null && moment(endDate).format("YYYY-MM-DD")}
            onChange={(startDate, endDate) => {
              startDate === null
                ? setStartDate(null)
                : setStartDate(moment(startDate).format("YYYY-MM-DD"));

              endDate === null
                ? setEndDate(null)
                : setEndDate(moment(endDate).format("YYYY-MM-DD"));
            }}
            dateFormat="MM/DD/YYYY"
            minDate={new Date(1900, 0, 1)}
            maxDate={new Date(2100, 0, 1)}
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
            disabled={false}
            startWeekDay="monday"
          />
          <small className="form-text text-muted">
            <b>Filter</b> by Date
          </small>
        </div>
        <div className="col-md-4">
          <ReactSelect
            className="basic-single"
            classNamePrefix="select"
            value={status.filter((obj) => obj.value === chargeSuccess)}
            isSearchable={true}
            name="chargeSuccess"
            options={status}
            onChange={(e) => {
              setChargeSuccess(e.value);
            }}
          />
          <small className="form-text text-muted">
            <b>Status</b>
          </small>
        </div>
        {(props.isMaster === true && ids.length === 0) && (
           <div className="col-md-4">
            <button
              type="button"
              className="btn btn-success  mt-4"
              disabled={isInvoiceLoading}
              onClick={() => {
                handleGetInvoicesByFilter();
              }}
            >
              Generate Master Invoice By Filter {pagination?.totalItemCount ? "- " + pagination?.totalItemCount : null} {isInvoiceLoading && <span className="spinner-border spinner-border-sm mb-1"></span>}
            </button>
            </div>
          )}
       {(props.isMaster === true && ids.length > 0) &&  
        <div className="col-md-4">
            <button
              type="button"
              className="btn btn-success mt-4"
              disabled={isInvoiceLoading}
              onClick={() => {
                generateInvoice();
              }}
            >
             Generate Master Invoice By Selected {ids.length ? "- " + ids.length : null}  {isInvoiceLoading && <span className="spinner-border spinner-border-sm mb-1"></span>}
            </button>
          </div>}
      </div>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination paginationProps={paginationProps} table="invoicesPageSize">
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={orders || []}
                columns={columns}
                defaultSorted={[{ dataField: "Created", order: "desc" }]}
                onTableChange={GetHandlerTableChange}
                selectRow={ props?.isMaster === true ? selectRow : undefined}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={orders || []} />
                <NoRecordsFoundMessage entities={orders || []} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>

      <Modal
        size="xl"
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <Invoice
            componentRef={componentRef}
            invoices={invoices}
            isLoading={isLoading}
            name={props.name}
          />
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            Close
          </Button>
          <div className="my-1 me-5">
            <button
              type="button"
              disabled={isSending}
              className="btn btn-light-info my-1 me-12"
              onClick={() => {
                SendInvoiceByEmail();
              }
              }
            >
              {
                isSending ? 'Sending...' : 'Send Invoice'
              }
            </button>
          </div>
          <div className="my-1 me-5">
            <button
              type="button"
              className="btn btn-light-primary my-1 me-12"
              onClick={handlePrint}
            >
              Print Invoice
            </button>
          </div>
          <div className="my-1 me-5">
            <button
              type="button"
              disabled={downloadIsLoading}
              className="btn btn-light-success my-1 me-12"
              onClick={()=> {
                setDownloadIsLoading(true);
                downloadBillAsPDF()
              }}
            >
              {downloadIsLoading ? 'Downloading...' : 'Download PDF'}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        size="xl"
        show={showShort}
        onHide={() => {
          setShowShort(false);
        }}
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <InvoiceShort
            componentRef={componentRef}
            invoices={invoicesShort}
            isLoading={isLoading}
            name={props.name}
          />
        </Modal.Body>
        <Modal.Footer className="border-0 ">
          <Button
            variant="secondary"
            onClick={() => {
              setShowShort(false);
            }}
          >
            Close
          </Button>
          <div className="my-1 me-5">
            <button
              type="button"
              disabled={isSending}
              className="btn btn-light-info my-1 me-12"
              onClick={() => {
                SendInvoiceByEmail();
              }
              }
            >
              {
                isSending ? 'Sending...' : 'Send Invoice'
              }
            </button>
          </div>
          <div className="my-1 me-5">
            <button
              type="button"
              className="btn btn-light-primary my-1 me-12"
              onClick={handlePrint}
            >
              Print Invoice
            </button>
          </div>
          <div className="my-1 me-5">
            <button
              type="button"
              disabled={downloadIsLoading}
              className="btn btn-light-success my-1 me-12"
              onClick={()=> {
                setDownloadIsLoading(true);
                downloadBillAsPDF()
              }}
            >
              {downloadIsLoading ? 'Downloading...' : 'Download PDF'}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PharmacyInvoicesHistoy;
