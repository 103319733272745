import React, { useMemo } from "react";
import { CustomCheckbox } from "./GlobalHelpers";

function SelectionCheckbox({ isSelected, onChange }) {
  return (
    <>
      <input type="checkbox" style={{ display: "none" }} />
      <label className="checkbox checkbox-single">
        <input type="checkbox" checked={isSelected} onChange={onChange} />
        <span />
      </label>
    </>
  );
}

function groupingItemOnSelect(props) {
  const { ids, setIds, customerId, isRow, setNames, Names, customer, ordersCodes, setOrdersCodes, backgroundCheckRequired,setBackgroundCheckRequired  } = props;
  const currentIndex = ids.findIndex(id => id === customerId);
  if (ids.some((id) => id === customerId)) {
    setIds(ids.filter((id) => id !== customerId));
    if(Names !== undefined){
      setNames(Names.filter((name) => name?.id !== customerId));
    }
    if (ordersCodes !== undefined) {
      setOrdersCodes(ordersCodes.filter((code) => code !== customer.orderCode));
    }
    if (backgroundCheckRequired !== undefined && currentIndex !== -1) {
      setBackgroundCheckRequired(backgroundCheckRequired.filter((_, index) => index !== currentIndex));
    }
  } else {
    const newIds = [...ids,customerId];
    setIds(newIds);

   
    if(Names !== undefined){
      const newNames = [...Names,customer];
      setNames(newNames);
    }
    if (ordersCodes !== undefined) {
      const newOrdersCodes = [...ordersCodes, customer.orderCode];
      setOrdersCodes(newOrdersCodes);
    }
    if (backgroundCheckRequired !== undefined && currentIndex === -1) {
      const newBackgroundChecks = [...backgroundCheckRequired, customer.backgroundCheckRequired];
      setBackgroundCheckRequired(newBackgroundChecks);
    }
  }
}

// check official documentations: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Selection&selectedStory=Custom%20Selection%20Column%20Header%20Style&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
export function getSelectRow(props) {
  const { entities, ids, setIds, isRow, setNames, Names, ordersCodes, setOrdersCodes,backgroundCheckRequired,setBackgroundCheckRequired  } = props;

  const handleRowSelect = (row, isSelect) => {
    console.log('row', row)
  const currentIndex = ids.findIndex(id => id === row.id);
    if (isSelect) {
      const order = {
        id: row.id,
        name: row.firstName + " " + row.lastName,
        orderCode: row.code,
        backgroundCheckRequired: row.backgroundCheckRequired
      };
      setIds((prevIds) => [...prevIds, row.id]);
      if (ordersCodes !== undefined){
        setOrdersCodes((prevIds) => [...prevIds, row.code]);
      }
      if(Names !== undefined){
        setNames((prevIds) => [...prevIds, order]);
      }
      if (backgroundCheckRequired !== undefined && currentIndex === -1) {
        const newBackgroundChecks = [...backgroundCheckRequired, row.backgroundCheckRequired];
        setBackgroundCheckRequired(newBackgroundChecks);
      }
    } else {
      setIds((prevIds) => prevIds.filter((id) => id !== row.id));
      if (ordersCodes !== undefined){
      setOrdersCodes((prevIds) => prevIds.filter((code) => code !== row.code));
      }
      if(Names !== undefined){
      setNames((prevIds) => prevIds.filter((el) => el.id !== row.id));
      }
      if (backgroundCheckRequired !== undefined && currentIndex !== -1) {
        setBackgroundCheckRequired(backgroundCheckRequired.filter((_, index) => index !== currentIndex));
      }
    }
  };
  
  const handleSelectAll = (isSelect, rows) => {
    const allNames = rows.map((el) => ({ id: el.id, name: el?.firstName + " " + el?.lastName }));
    const allOrdersCodes = rows.map((el) => el.code);
    const allBackgroundChecks = rows.map((el) => el.backgroundCheckRequired);
    if (isSelect) {
      setIds(rows.map((row) => row.id));
      if (Names !== undefined) {
        setNames(allNames);
      }
      if (setOrdersCodes !== undefined) {
        setOrdersCodes(allOrdersCodes);
      }
      if (setBackgroundCheckRequired !== undefined) {
        setBackgroundCheckRequired(allBackgroundChecks);
      }
    } else {
      setIds([]);
      if(Names !== undefined) {
        setNames([])
      }
      if (setOrdersCodes !== undefined) {
        setOrdersCodes([]);
      }
      if (setBackgroundCheckRequired !== undefined) {
        setBackgroundCheckRequired([]);
      }
    }
  };

  return {
    mode: 'checkbox',
    clickToSelect: false,
    selected: ids,
    onSelect: handleRowSelect,
    onSelectAll: handleSelectAll,
    selectionRenderer: (props) => <CustomCheckbox {...props} />,
    selectionHeaderRenderer: (props) => <CustomCheckbox {...props} />,
  };
}
