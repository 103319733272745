/* eslint-disable jsx-a11y/anchor-is-valid */
import objectPath from "object-path";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link,useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { toAbsoluteUrl } from "../../../_helpers";
import { apiCalll } from "../../../_helpers/apiHelpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Brand } from "../brand/Brand";
import { QuickUserToggler } from "../extras/QuickUserToggler";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { useCount } from "../extras/dropdowns/search/contextCount";
import { KTUtil } from "./../../../_assets/js/components/util";
import { AsideSearch } from "./AsideSearch";
import { AsideMenu } from "./aside-menu/AsideMenu";


export function Aside() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { token } = JSON.parse(localStorage.getItem("user"));
  const [menu, setMenu] = useState([]);
  const history = useHistory();
  const [hideOtc, setHideOtc] = useState(true);

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      asideSecondaryDisplay: objectPath.get(
        uiService.config,
        "aside.secondary.display"
      ),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      extrasSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      extrasNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      extrasQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      extrasQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      extrasUserDisplay: objectPath.get(
        uiService.config,
        "extras.user.display"
      ),
    };
  }, [uiService]);

  const tabs = {
    tabId1: "kt_aside_tab_1",
    tabId2: "kt_aside_tab_2",
    tabId3: "kt_aside_tab_3",
    tabId4: "kt_aside_tab_4",
    tabId5: "kt_aside_tab_5",
    tabId6: "kt_aside_tab_6",
    tabId7: "kt_aside_tab_7",
    tabId8: "kt_aside_tab_8",
    tabId9: "kt_aside_tab_9",
    tabId10: "kt_aside_tab_10",
  };
  const [activeTab, setActiveTab] = useState(tabs.tabId1);
  const handleTabChange = (id) => {
    setActiveTab(id);
    const asideWorkspace = KTUtil.find(
      document.getElementById("kt_aside"),
      ".aside-secondary .aside-workspace"
    );
    if (asideWorkspace) {
      KTUtil.scrollUpdate(asideWorkspace);
    }
  };

  async function handleMenu() {
    await fetch(BASE_URL + "/Generic/Menu", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      Allow: "GET",
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setMenu(data[1]?.permissions);
          setHideOtc(data[1]?.hideOtc);
        }
        else if (data[0] === 401) {
          history.push("/logout");
      }else if (data[0] === 450) {
        history.push("/setup/twoFactor");
      }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function checkBuilder() {
    apiCalll({
      method: "GET",
      link: `/Generic/AppVersion`,
    }).then((data) => {
      if (data[0] === 200) {
        if (String(window.location.hostname) !== "localhost") {
          if (String(process.env.REACT_APP_VERSION) !== String(data[1])) {
            Swal.fire({
              position: "center",
              icon: "info",
              title: "New Build Version Released. Clearing Cache now ...",
              showConfirmButton: false,
              timer: 5000,
            });
            setTimeout(function () {
              if (caches) {
                caches.keys().then((names) => {
                  for (const name of names) {
                    caches.delete(name);
                  }
                });
              }
              window.location.reload(true);
            }, 2000);
          }
        }
      }
      return data;
    });
  }
  const handleRemoveSpaces = (name) => {
    // Check if the input string contains a space
    if (name.includes(' ')) {
      // If there is a space, remove it and update the state
      const resultString = name.replace(/ /g, '');
      return resultString;
    }

    return name
  };
  useEffect(() => {
    handleMenu();
  }, []);
  const { count, setCount } = useCount();

  return (
    <>
      {/* begin::Aside */}
      <div id="kt_aside"className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}>
        {/* begin::Primary */}
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
          <Brand />
          {/* begin::Nav Wrapper */}
          <div className="aside-nav d-flex flex-column align-items-center  pt-5 scroll scroll-pull">
            {/* begin::Nav */}
            <ul className="list-unstyled flex-column" role="tablist">
              {menu.map((item, index) => {
                if (item.position > 0)
                  return (
                  (hideOtc === true && item.position === 950) ? "" :
                    <Fragment
                      key={index}
                    >
                      <li
                        id={`${handleRemoveSpaces(item.name)}`}
                        className="nav-item mb-3"
                        data-toggle="tooltip"
                        data-placement="rigth"
                        data-container="body"
                        data-boundary="window"
                      >
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id="metronic-features">
                              {item?.name}
                            </Tooltip>
                          }
                        >
                          <Link
                            to={item?.link}
                            className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                              tabs.tabId1 + index && "active"}`}
                            data-toggle="tab"
                            data-target={`#${tabs.tabId1 + index}`}
                            onClick={() => {
                              checkBuilder();
                              handleTabChange(tabs.tabId1 + index);
                            }}
                            role="tab"
                          >
                            <span className="svg-icon svg-icon-lg">
                              <SVG src={toAbsoluteUrl(item?.icon)} />
                            </span>
                          </Link>
                        </OverlayTrigger>

                        {item?.name === "RxDrop" && count > 0 && (
                          <span
                            className="label label-danger mb-5 mr-2"
                            style={{
                              height: "auto",
                              position: "absolute",
                              right: "-5px",
                              top: "9.5rem",
                              width: "auto",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                            }}
                          >
                            {count ?? 0}
                          </span>
                        )}
                      </li>
                    </Fragment>
                  );
              })}

              {/* end::Item */}
            </ul>
            {/* end::Nav */}
          </div>
          {/* end::Nav Wrapper */}

          {/* begin::Footer */}
          <div className="aside-footer d-flex flex-column align-items-center flex-column-auto pb-4 pb-lg-10">
            {/* begin::Aside Toggle */}
            {/* {layoutProps.asideSecondaryDisplay &&
              layoutProps.asideSelfMinimizeToggle && (
                <>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="toggle-aside">Toggle Aside</Tooltip>}
                  >
                    <span
                      className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                      id="kt_aside_toggle"
                    >
                      <i className="ki ki-bold-arrow-back icon-sm" />
                    </span>
                  </OverlayTrigger>
                </>
              )} */}
            {/* end::Aside Toggle */}

            {/* begin::Search */}
            {layoutProps.extrasSearchDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="toggle-search">Quick Search</Tooltip>}
              >
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1"
                  id="kt_quick_search_toggle"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {/* end::Search */}

            {/* begin::Notifications */}
            {layoutProps.extrasNotificationsDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="toggle-notifications">Notifications</Tooltip>
                }
              >
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                  id="kt_quick_notifications_toggle"
                  data-placement="right"
                  data-container="body"
                  data-boundary="window"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {/* end::Notifications */}

            {/* begin::Quick Actions */}
            {/* {layoutProps.extrasQuickActionsDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="toggle-quick-actions">Quick Actions</Tooltip>
                }
              >
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1"
                  id="kt_quick_actions_toggle"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Media/Equalizer.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )} */}
            {/* end::Quick Actions */}

            {/* begin::Quick Panel */}
            {/* {layoutProps.extrasQuickPanelDisplay && (
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="toggle-quick-panel">Quick Panel</Tooltip>}
              >
                <a
                  href="#"
                  className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                  id="kt_quick_panel_toggle"
                  data-placement="right"
                  data-container="body"
                  data-boundary="window"
                >
                  <span className="svg-icon svg-icon-lg">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Layout/Layout-4-blocks.svg"
                      )}
                    />
                  </span>
                  <span className="label label-sm label-light-danger label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1">
                    3
                  </span>
                </a>
              </OverlayTrigger>
            )} */}
            {/* end::Quick Panel */}

            {/* begin::Languages*/}
            {layoutProps.extrasLanguagesDisplay && <LanguageSelectorDropdown />}
            {/* end::Languages */}

            {/* begin::User*/}
            {layoutProps.extrasUserDisplay && <QuickUserToggler isActive={activeTab === tabs.tabId1} menu={menu} handleTabChange={handleTabChange}/>}
            {/* end::User */}
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Primary */}

        {layoutProps.asideSecondaryDisplay && (
          <>
            {/* begin::Secondary */}
            <div className="aside-secondary d-flex flex-row-fluid">
              {/* begin::Workspace */}
              <div className="aside-workspace scroll scroll-push my-2">
                <div className="tab-content">
                  <AsideSearch isActive={activeTab === tabs.tabId1} />
                  <AsideMenu isActive={activeTab === tabs.tabId2} />
                </div>
              </div>
              {/* end::Workspace */}
            </div>
            {/* end::Secondary */}
          </>
        )}
      </div>
      {/* end::Aside */}
    </>
  );
}
